import VuePortal from '@linusborg/vue-simple-portal'
import flits from '@plugins/flits'
import vDialog from '@ts/components/v-dialog'
import Vue from 'vue'

Vue.use(flits)

Vue.use(VuePortal, {
  name: 'portal',
})

Vue.use(vDialog)
Vue.use(flits)
