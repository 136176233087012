import Vue from 'vue'
import Component from 'vue-class-component'
import { mixin as clickaway } from 'vue-clickaway'

@Component({
  mixins: [clickaway],
})
export default class Icon extends Vue {
  public isOpen: boolean = false

  public toggle() {
    this.isOpen = !this.isOpen
  }

  public close() {
    this.isOpen = false
  }

  public open() {
    this.isOpen = true
  }

  public beforeDestroy() {
    this.close()
  }
}
