import _Vue from 'vue'
import VueComponent from 'vue'
import vDialog from './index.vue'
import DialogComponent from './script'

export interface DialogInterface {
  register(dialog: VueComponent): void
  deregister(dialog: VueComponent): void
  open(dialogName: string): void
  close(dialogName: string): void
  toggle(dialogName: string): void
  closeModal(name: string): void
}

class DialogManager {
  private dialogs = new Map<string, DialogComponent>()

  register(dialog: DialogComponent) {
    const name = dialog.name
    if (this.getDialog(name)) {
      throw new Error(`Dialog names must be unique, name: "${name}" has already been registered.`)
    }

    this.dialogs.set(name, dialog)
  }

  deregister(dialog: DialogComponent) {
    this.dialogs.delete(dialog.name)
  }

  open(name: string) {
    this.getDialog(name).showHandler(true)
  }

  close(name: string) {
    this.getDialog(name).showHandler(false)
  }

  toggle(name: string) {
    const dialog = this.getDialog(name)
    dialog.showHandler(!dialog.show)
  }

  closeModal(name: string) {
    this.getDialog(name).closeModal()
  }

  getDialog(name: string): DialogComponent {
    return this.dialogs.get(name)
  }
}

export default {
  install(Vue: typeof _Vue, plugin = '$dialog') {
    const dialogManager = new DialogManager()

    Vue.prototype[plugin] = {
      register(dialog: DialogComponent) {
        dialogManager.register(dialog)
      },
      deregister(dialog: DialogComponent) {
        dialogManager.deregister(dialog)
      },
      open(name: string) {
        dialogManager.open(name)
      },
      close(name: string) {
        dialogManager.close(name)
      },
      toggle(name: string) {
        dialogManager.toggle(name)
      },
      closeModal(name: string) {
        dialogManager.closeModal(name)
      },
    }

    _Vue.component('v-dialog', vDialog)
  },
}
