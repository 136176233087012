import tus from 'tus-js-client'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif']

export interface UploadedFileObject {
  fileID: string
  name: string
  type: string
}

@Component
export default class VImageUpload extends Vue {
  @Prop({ type: String }) image: string
  @Prop({ type: String, default: '/upload' }) endpoint: string
  @Prop({ type: String, default: 'file' }) name: string
  @Prop({ type: String, default: 'image/*' }) accept: string
  @Prop({ type: String }) label: string
  @Prop({ type: String }) icon: string

  public progress: number = 0
  public fileName: string = ''
  public fileType: string = ''
  public localIcon: string = ''
  public state: string = ''
  public localImage: string = ''
  public fileID: string = ''

  get displayLabel(): string {
    if (this.label.length) {
      return this.label
    }

    return this.$t('upload image') as string
  }

  get title(): string {
    return this.displayLabel
  }

  get hasImage(): boolean {
    return this.localImage.length > 0
  }

  get hasIcon(): boolean {
    return this.localIcon && this.localIcon.length > 0
  }

  public resetFile() {
    this.fileName = ''
    this.fileType = ''
    this.progress = 0
    this.localIcon = this.icon
    this.state = ''
  }

  public showProgress() {
    this.localIcon = 'loader'
    this.state = 'uploading'
  }

  public filesSelected(event: Event) {
    const target = event.target as HTMLInputElement
    const files = target.files
    const file = files[0]

    Promise.all([this.uploadFile(file), this.readFile(file)]).then((result) => {
      const obj: UploadedFileObject = {
        fileID: this.fileID,
        name: this.fileName,
        type: this.fileType,
      }

      this.$emit('complete', obj)
    })
  }

  public readFile(file: File) {
    if (IMAGE_TYPES.indexOf(file.type) === -1) {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event: Event) => {
        const target = event.target as FileReader
        this.localImage = target.result as string

        resolve()
      }
    })
  }

  public uploadFile(file: File) {
    return new Promise((resolve) => {
      this.showProgress()

      this.fileName = file.name
      this.fileType = file.type

      const upload = new tus.Upload(file, {
        endpoint: this.endpoint,
        resume: true,
        chunkSize: 1.5 * 1024 * 1024,
        metadata: {
          filename: file.name,
          type: file.type,
        },
        retryDelays: [0, 1000, 3000, 8000],

        onSuccess: () => {
          this.handleSuccess(upload)
          resolve()
        },

        onProgress: (sent, total) => {
          this.handleProgress(sent, total)
        },
      })

      upload.start()
    })
  }

  public handleProgress(sent: number, total: number) {
    this.progress = Math.round((sent / total) * 100)
  }

  public handleSuccess(upload: tus.Upload) {
    this.localIcon = 'check'
    this.state = 'success'

    this.fileID = upload.url.split('/').pop()
    this.$emit('uploadcomplete', this.fileID)

    setTimeout(() => {
      this.localIcon = this.icon
    }, 3000)
  }

  @Watch('image', { immediate: true })
  private imagePropChanged(newValue: string) {
    this.localImage = newValue

    if (newValue.length === 0) {
      this.state = 'empty'
    }
  }

  @Watch('icon', { immediate: true })
  private iconPropChange(newValue: string) {
    this.localIcon = newValue
  }
}
