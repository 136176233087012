import Vue from 'vue'
import Component from 'vue-class-component'

@Component
class Navigation extends Vue {
  public showNavigation: boolean = false

  public toggleNavigation() {
    this.showNavigation = !this.showNavigation
  }
}

export default Navigation
