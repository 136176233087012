import Vue from 'vue'
import Component from 'vue-class-component'
import { http } from '../plugins/http'

@Component
class Feedback extends Vue {
  public feedbackMessage: string = ''

  public showFeedbackDialog() {
    this.$dialog.open('app-feedback')
  }

  public submitFeedback(event: Event) {
    const target = event.target as HTMLFormElement
    const message = this.feedbackMessage

    if (message.length < 1) {
      return
    }

    http
      .post(target.action, {
        message,
        window: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        screen: {
          width: window.screen.width,
          height: window.screen.height,
        },
      })
      .then((response) => {
        const data = response.data

        this.$flits.success(data.message)
        this.$dialog.close('app-feedback')

        this.$nextTick(() => {
          this.feedbackMessage = ''
        })
      })
  }
}

export default Feedback
