import { http } from '@ts/plugins/http'
import { empty, uuid } from '@ts/shared/utils'
import { TranslateResult } from 'vue-i18n'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DeleteConfirm extends Vue {
  @Prop({ type: String }) url: string
  @Prop({ type: Function }) method: Function
  @Prop({ type: Boolean, default: true }) redirect: boolean
  @Prop({ type: String }) confirmMessage: string | TranslateResult

  private get confirmMessageLocal(): string | TranslateResult {
    return !empty(this.confirmMessage)
      ? this.confirmMessage
      : this.$t("You're about to perform a possibly irreversable action, are you sure you want to continue?")
  }

  private get modalName() {
    return uuid()
  }

  private confirm() {
    this.$dialog.closeModal(this.modalName)

    if (!empty(this.url)) {
      this.delete()
    }

    if (!empty(this.method)) {
      this.method()
    }
  }

  private async delete() {
    try {
      const result: any = await http.delete(this.url, {
        params: {
          ...(this.redirect && { redirect: this.redirect }),
        },
      })

      const redirectUrl: string = result.data.url
      const message: string = result.data.message

      if (this.redirect && !empty(redirectUrl)) {
        window.location.href = redirectUrl
      } else {
        this.$flits.success(message)
        this.$emit('deleted')
      }
    } catch (e) {
      this.$emit('error', e)
    }
  }
}
