export const isPromise = (obj: any) => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}

export function randomInRange(start: number, end: number) {
  return Math.floor(Math.random() * (end - start + 1) + start)
}

export function sequence(start: number, stop: number, step: number) {
  return Array.from({ length: (stop - start) / step + 1 }, (_: number, i: number) => start + i * step)
}

export function empty(obj: any) {
  return obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)
}

type primitive = undefined | null | boolean | string | number

export function arePrimitiveArraysEqual(a: primitive[], b: primitive[]) {
  return a.every((value: primitive, index: number) => {
    return value === b[index]
  })
}

export function uuid() {
  let r = null
  let v = null

  const result: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    r = (Math.random() * 16) | 0
    v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

  return result
}
