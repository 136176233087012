import VueComponent from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

export interface DialogComponent extends VueComponent {
  show: boolean
  showHandler(visible: boolean): void
  close(): void
  closeModal(): void
}

@Component
export default class Dialog extends Vue {
  @Prop({ default: false }) isModal: Boolean
  @Prop({ required: true }) name: string

  public show: Boolean = false
  private selector: string = '#dialog'

  @Watch('show')
  public showHandler(show: Boolean) {
    if (show === true) {
      this.open()
      document.body.classList.add('modal-open')
    } else {
      this.close()
      document.body.classList.remove('modal-open')
    }
  }

  public open(): void {
    this.show = true
  }

  public close(): void {
    if (this.isModal) {
      return
    }
    this.show = false
  }

  public closeModal(): void {
    this.show = false
  }

  public mounted() {
    document.addEventListener('keyup', this.keyPressed)
  }

  public beforeDestroy() {
    document.body.classList.remove('modal-open')
    document.removeEventListener('keyup', this.keyPressed)
    this.$dialog.deregister(this)
  }

  public created() {
    this.$dialog.register(this)
  }

  private keyPressed(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close()
    }
  }
}
