import messages from '@locale/nl.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'nl',
  messages: { nl: messages },
})

export default i18n

export { i18n }
